import { Fade } from "@mui/material";
import { Grid } from "@mui/material";
const Centerer = ({ children, fade }) => {
  return fade ? (
    <Fade timeout={500} in={true}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        <Grid item xs={3}>
          {children}
        </Grid>
      </Grid>
    </Fade>
  ) : (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid item xs={3}>
        {children}
      </Grid>
    </Grid>
  );
};

export default Centerer;
