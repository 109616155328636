import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { InnerFrameLoader } from "../common";
import { Box, Typography, Button } from "@mui/material";

const MyMap = () => {
  const mapStyle = {
    height: "40rem",
    width: "80%", // Adjust the width as needed
    margin: "auto", // Center the map horizontally
    border: "2px solid #3498db", // Add a beautiful border
    borderRadius: "10px", // Optional: Add rounded corners,
    position: "relative",
    display: "grid",
    gridTemplateRows: "12fr 1fr 1fr",
  };

  const [isInPinPlacementMode, setIsInPinPlacementMode] = useState(false);

  const [userLocationLoading, setUserLocationLoading] = useState(true);
  const [userLocation, setUserLocation] = useState(null);
  const [targetingPosition, setTargetingPosition] = useState([51.505, -0.09]);
  console.log("a", "targetingPosition:", targetingPosition);

  let watchId = null;
  const handleMarkerDrag = (e) => {
    setTargetingPosition(e.target.getLatLng());
  };
  useEffect(() => {
    if (userLocation) return;

    // Use the Geolocation API to get the user's location
    if (navigator.geolocation) {
      console.log("navigator.geolocation called again");
      watchId = navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation([latitude, longitude]);
          setUserLocationLoading(false);
        },
        (error) => {
          console.error("Error getting user location:", error);
          setUserLocationLoading(false);
        }
      );
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation([latitude, longitude]);
          setUserLocationLoading(false);
        },
        (error) => {
          console.error("Error getting user location:", error);
          setUserLocationLoading(false);
        }
      );
    } else {
      setUserLocationLoading(false);
    }

    // Clear the watch when the component unmounts
    return () => {
      if (watchId) {
        navigator.geolocation.clear(watchId);
      }
    };
  }, []);

  // Pin placement mode

  const togglePlacementMode = () => {
    return setIsInPinPlacementMode(!isInPinPlacementMode);
  };

  const MapControls = () => {
    return (
      <Box
        display={"grid"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        zIndex={"modal"}
        textAlign={"center"}
        position={"relative"}
        padding={1}
        backgroundColor={"rgba(255, 255, 255, 0.8)"}
        sx={{
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      >
        <Typography variant="h5" component="p" padding={1}>
          Place your pin on the map
        </Typography>

        <Box pointerEvents={"auto"}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setIsInPinPlacementMode(false)}
          >
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={() => {}}>
            Comment
          </Button>
        </Box>
      </Box>
    );
  };

  const DraggablePin = () => {
    return (
      <Marker
        position={targetingPosition}
        draggable={true}
        onDragend={handleMarkerDrag}
        icon={
          new L.Icon({ iconUrl: "path/to/your/icon.png", iconSize: [32, 32] })
        }
      >
        <Popup>Your Marker</Popup>
      </Marker>
    );
  };

  if (userLocationLoading) {
    return <InnerFrameLoader />;
  }

  return (
    <>
      <MapContainer
        style={mapStyle}
        center={userLocation || [33.4153443, -111.834528]}
        zoom={20}
        scrollWheelZoom={false}
        className="map-container-relative"
      >
        {/* {isInPinPlacementMode && (
        )} */}

        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors {s} {z} {x} {y}'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        <DraggablePin />
        <Marker position={userLocation || [51.505, -0.09]}>
          <Popup>
            A pretty CSS3 popup. <br /> Easily customizable.
          </Popup>
        </Marker>
        <MapControls />
      </MapContainer>
    </>
  );
};

export default MyMap;
