import { createContext, useState, useEffect, useContext } from "react";

const LocationPermissionContext = createContext({
  locationLoading: false,
  locationDetails: undefined,
});

const LocationPermissionProvider = ({ children }) => {
  const [locationData, setLocationData] = useState(null);
  const [locationLoading, setLocationLoading] = useState(true);

  const askForLocationPermission = () => {
    if (locationData?.state === "denied") return;
    setLocationData({ state: "asking" });
    navigator.geolocation.getCurrentPosition(
      () => {
        setLocationData({
          state: "granted",
          onchange: null,
          name: "geolocation",
        });
      },
      () => {
        setLocationData({
          state: "denied",
          onchange: null,
          name: "geolocation",
        });
      }
    );
  };

  const value = {
    locationLoading,
    locationData,
    askForLocationPermission,
  };

  // Get current state of location permission
  useEffect(async () => {
    setLocationLoading(true);
    try {
      const navigatorPermissionResult = await navigator.permissions.query({
        name: "geolocation",
      });
      setLocationData(navigatorPermissionResult);
    } catch (error) {
      console.error("Error getting location permission:", error);
    } finally {
      setLocationLoading(false);
    }
  }, []);

  return (
    <LocationPermissionContext.Provider value={value}>
      {children}
    </LocationPermissionContext.Provider>
  );
};

function useLocationPermission() {
  const locationPermissionContext = useContext(LocationPermissionContext);
  if (locationPermissionContext === undefined) {
    throw new Error(
      "useLocationPermission must be used within a LocationPermissionProvider"
    );
  }
  return locationPermissionContext;
}

export { LocationPermissionProvider, useLocationPermission };
