import { createTheme, responsiveFontSizes } from "@mui/material/styles";

const rawTheme = createTheme({
  palette: {
    primary: {
      main: "#00796B",
      dark: "#004C40",
      light: "#48A999",
    },
    secondary: {
      main: "#FFB300",
      dark: "#FF8F00",
      light: "#FFD54F",
    },
    background: {
      main: "#FAFAFA",
    },
    accent: {
      main: "#FF6F61",
      dark: "#E55D52",
      light: "#FF897D",
    },
    text: {
      main: "#333333",
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          backgroundColor: "#e0e0e0",
          padding: "12px",
        },
      },
      defaultProps: {
        disableGutters: true,
        maxWidth: false,
      },
    },
  },
});

const theme = responsiveFontSizes(rawTheme);

export default theme;
