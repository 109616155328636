import { Box, CircularProgress, Typography } from "@mui/material";

const InnerFrameLoader = () => {
  return (
    <Box textAlign={"center"} flexDirection={"column"}>
      <Typography variant="h5" m={2} component="p">
        Loading your location...
      </Typography>
      <CircularProgress />
    </Box>
  );
};

export default InnerFrameLoader;
