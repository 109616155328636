import { AppBar, Toolbar, IconButton, Link } from "@mui/material";
import { GitHub } from "@mui/icons-material";

const Footer = ({ userLocation }) => {
  const date = new Date();
  console.log("a", "footer");
  console.log(date.toLocaleTimeString());
  return (
    <AppBar position="static" color="secondary">
      <Toolbar sx={{ justifyContent: "center" }}>
        Full Refresh @ {date.toLocaleTimeString()} | {date.toLocaleDateString()}{" "}
        | {userLocation && userLocation[0]}, {userLocation && userLocation[1]}
        <Link
          href="https://github.com/louiemontes"
          target="_blank"
          rel="noopener"
        >
          <IconButton color="inherit" aria-label="GitHub">
            <GitHub />
          </IconButton>
        </Link>
      </Toolbar>
    </AppBar>
  );
};

export default Footer;
