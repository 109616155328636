import React from "react";
import {
  Button,
  TextField,
  Backdrop,
  CircularProgress,
  Box,
  Typography,
  Stack,
  Divider,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Centerer } from "../common";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { app } from "../../firebase-connection";

import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

const CreateAccount = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const validationSchema = yup.object({
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
    password: yup
      .string("Enter your password")
      .min(6, "Password needs to be a minimum of six characters long")
      .required("Password is required"),
  });

  const defaultValues = {
    email: "",
    password: "",
  };

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors, isSubmitting, setSubmitting },
  } = useForm({
    reValidateMode: "onBlur",
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  });

  const onSubmit = async (event) => {
    console.log("a", "event:", event);
    const { password, email } = event;
    const auth = getAuth(app);
    try {
      console.log("a", "password:", password);
      console.log("a", "email:", email);
      await createUserWithEmailAndPassword(auth, email, password);
      navigate("/world");
    } catch (e) {
      console.log("a", "e:", e);
      setError("email", { message: e?.message });
    }

    return;
  };

  return (
    <Centerer fade>
      <Typography align="center" mb={2} variant="h6">
        Create Account
      </Typography>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Stack direction={"row"} gap={2} minHeight={theme.spacing(8)}>
          <Controller
            control={control}
            name={`email`}
            defaultValue=""
            render={({ field }) => {
              return (
                <TextField
                  {...field}
                  label="Email"
                  error={errors?.email}
                  helperText={errors?.email?.message}
                  fullWidth
                />
              );
            }}
          />
          <Controller
            control={control}
            name={`password`}
            render={({ field }) => (
              <TextField
                {...field}
                label="Password"
                type="password"
                error={errors?.email}
                helperText={errors?.email?.message}
                fullWidth
              />
            )}
          />
        </Stack>
        <Divider pt={20}></Divider>
        <Stack mt={2} direction={"column"} gap={2}>
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => navigate("/welcome")}
          >
            Back
          </Button>
          <Button
            color="primary"
            type="submit"
            variant="contained"
            sx={{ float: "right" }}
          >
            Submit
          </Button>
        </Stack>
      </Box>
      <Backdrop
        sx={{ color: "black", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isSubmitting}
        onClick={() => setSubmitting(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Centerer>
  );
};

export default CreateAccount;
