import React, { useMemo } from "react";
import { Grid } from "@mui/material";
import Frame from "../common/frame";
import { useLocation, useNavigate } from "react-router-dom";
import { GlossyButton } from "../common";
import { useLocationPermission } from "../../firebase-connection/LocationContext";

const Welcome = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { askForLocationPermission, locationData } = useLocationPermission();

  const handleManualPermssionUnsetClick = () => {
    const userAgent = navigator.userAgent;
    let url = "";

    if (userAgent.includes("Chrome")) {
      url = "https://support.google.com/chrome/answer/114662?hl=en";
    } else if (userAgent.includes("Firefox")) {
      url =
        "https://support.mozilla.org/en-US/kb/permissions-manager-give-ability-store-passwords-set-cookies-more";
    } else if (userAgent.includes("Edg")) {
      url =
        "https://support.microsoft.com/en-us/microsoft-edge/change-site-permissions-in-microsoft-edge-4e1b1d3c-06bb-41b3-a0e6-b5f6a6e0b13b";
    } else if (userAgent.includes("Safari")) {
      url =
        "https://support.apple.com/guide/safari/manage-website-settings-ibrw005ec360/mac";
    } else if (userAgent.includes("Opera")) {
      url = "https://help.opera.com/en/latest/security-and-privacy/#siteData";
    } else {
      alert(
        "Browser not recognized. Please manually manage your site permissions."
      );
      return;
    }

    window.open(url, "_blank");
  };

  const decipheredAskForLocationButton = useMemo(() => {
    if (locationData?.state === "granted") {
      return (
        <GlossyButton
          onClick={handleManualPermssionUnsetClick}
          color={"secondary"}
          variant="primary"
          size="large"
        >
          Location Permission Granted
        </GlossyButton>
      );
    } else if (locationData?.state === "denied") {
      return (
        <GlossyButton
          onClick={handleManualPermssionUnsetClick}
          color={"secondary"}
          variant="outlined"
          size="large"
        >
          Location Permission Denied
        </GlossyButton>
      );
    }

    return (
      <GlossyButton
        onClick={askForLocationPermission}
        color="primary"
        size="large"
        variant="contained"
        glossy={locationData?.state === "asking" ? undefined : "true"}
        disabled={locationData?.state === "asking"}
      >
        {locationData?.state === "asking" ? "Asking..." : "Get World Pinning!"}
      </GlossyButton>
    );
  }, [locationData?.state, pathname]);

  const decipheredWorldNavigationButton = useMemo(() => {
    switch (locationData?.state) {
      case "asking":
        return (
          <GlossyButton disabled variant="contained" size="large" glossy="true">
            Could Be You!
          </GlossyButton>
        );
      case "granted":
        return (
          <GlossyButton
            onClick={() => {
              console.log("navigating");
              navigate("/world");
            }}
            variant="contained"
            size="large"
            glossy="true"
          >
            Go To World!
          </GlossyButton>
        );
      case "denied":
        return (
          <GlossyButton disabled variant="contained" size="large">
            World Unaccessible
          </GlossyButton>
        );
      default:
        return <></>;
    }
  }, [locationData?.state]);

  return (
    <>
      <Grid item>{decipheredAskForLocationButton}</Grid>
      <Grid item>{decipheredWorldNavigationButton}</Grid>
    </>
  );
};

export default Welcome;
