import { Box } from "@mui/material";
import Header from "./header";
import Footer from "./footer";
import { Grid, CircularProgress } from "@mui/material";

const CommonGridCenterer = ({ children }) => {
  return (
    <Grid
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      container
      direction={"column"}
      rowGap={4}
      minHeight={"100%"}
      overflow="scroll"
    >
      {children}
    </Grid>
  );
};

const Frame = ({
  children,
  includeCommonGridCenterer,
  viewLoading = false,
  userLocation,
}) => {
  const renderMainContent = (viewLoading, includeCommonGridCenterer) => {
    if (viewLoading) {
      return (
        <CommonGridCenterer>
          <Grid item>
            <CircularProgress color="inherit" />
          </Grid>
        </CommonGridCenterer>
      );
    } else if (includeCommonGridCenterer) {
      return <CommonGridCenterer>{children}</CommonGridCenterer>;
    }

    return children;
  };

  return (
    <Box
      className="layout"
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <Header />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
        }}
      >
        {renderMainContent(viewLoading, includeCommonGridCenterer)}
      </Box>
      <Footer userLocation={userLocation} />
    </Box>
  );
};

export default Frame;
