const knownErrors = {
  "auth/email-already-in-use": { email: "Email In Use" },
  "auth/invalid-email": { email: "Enter a valid email" },
};

export const firebaseErrorSurfacer = (rawError) => {
  const unknownError = { email: "Unknown Error" };
  const { code } = rawError;
  if (!code) return unknownError;
  if (knownErrors[code]) return knownErrors[code];
  return unknownError;
};
