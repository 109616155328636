import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import { firebaseErrorSurfacer } from "./utils";

const firebaseConfig = {
  apiKey: "AIzaSyBI7nlnYkqcKLxg5-lKzlzokCCLBLZ4Vh8",
  authDomain: "roar-here.firebaseapp.com",
  projectId: "roar-here",
  storageBucket: "roar-here.appspot.com",
  messagingSenderId: "731273671360",
  appId: "1:731273671360:web:9ef0dfaf843a0c908b7325",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

const signInWithGoogle = () => {
  signInWithPopup(auth, googleProvider)
    .then((result) => {
      console.log("a", "result:", result);
    })
    .catch((error) => {
      console.log("a", "error:", error);
      const errorCode = error.code;
      const errorMessage = error.message;
      const email = error.customData.email;
    });
};

const signOut = () => {
  auth.signOut();
};

googleProvider.addScope("https://www.googleapis.com/auth/contacts.readonly");

export {
  firebaseErrorSurfacer,
  auth,
  app,
  googleProvider,
  signInWithGoogle,
  signOut,
};
