import React from "react";
import { Centerer } from "../common";
import { Button, Stack, Typography, Box } from "@mui/material";
import SearchOffIcon from "@mui/icons-material/SearchOff";

import { useNavigate } from "react-router-dom";

const Lost = () => {
  const navigate = useNavigate();

  return (
    <Centerer fade>
      <Box textAlign={"center"}>
        <SearchOffIcon fontSize="large"></SearchOffIcon>
      </Box>
      <Typography align="center" variant="h6">
        Nothing to see here... (404)
      </Typography>
      <Typography align="center" mb={2} variant="body2">
        Lets get you back to a place of welcoming!
      </Typography>

      <Stack mt={10} direction="column" spacing={2}>
        <Button
          color="primary"
          type="submit"
          variant="contained"
          onClick={() => navigate("/welcome")}
        >
          Go To Welcome
        </Button>
      </Stack>
    </Centerer>
  );
};

export default Lost;
