import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  TextField,
  Backdrop,
  CircularProgress,
  Box,
  Typography,
  Stack,
  useTheme,
  Divider,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";

import * as yup from "yup";
import { Centerer } from "../common";
import { useNavigate, useLocation } from "react-router-dom";
import { app, auth } from "../../firebase-connection";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

import { useAuthState } from "react-firebase-hooks/auth";

import { useMemo } from "react";
import { signInWithGoogle } from "../../firebase-connection";
import GoogleButton from "react-google-button";

const Login = () => {
  const [showCustomLogin, setShowCustomLogin] = useState(false);
  const [authUser, authLoading] = useAuthState(auth);

  const locationFrom = useLocation()?.state?.from || "Unknown";
  console.log("a", "locationFrom:", locationFrom);
  // console.log("a", "useLocation:", useLocation());

  const navigate = useNavigate();
  const theme = useTheme();

  const validationSchema = yup.object({
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
    password: yup
      .string("Enter your password")
      .min(6, "Password needs to be a minimum of six characters long")
      .required("Password is required"),
  });

  const defaultValues = {
    email: "",
    password: "",
  };

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors, isSubmitting, setSubmitting },
  } = useForm({
    reValidateMode: "onBlur",
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  });

  const loginTitle = useMemo(() => {
    if (locationFrom === "RequiredRoute") return "Let's Login First!";
    return "Login";
  }, [locationFrom]);

  const onSubmit = async (event) => {
    setSubmitting(true);
    const { email, password } = event;
    const auth = getAuth(app);
    try {
      const response = await signInWithEmailAndPassword(auth, email, password);

      console.log("a", "response:", response);

      navigate("/");
    } catch (e) {
      setError("email", { message: firebaseErrorSurfacer(e?.message) });
    }
  };

  useEffect(() => {
    if (authUser) navigate("/");
  }, [authUser, authLoading]);

  return (
    <Centerer fade>
      <Typography align="center" mb={2} variant="h6">
        {loginTitle}
      </Typography>
      <Stack
        gap={2}
        // display="flex"
        // justifyContent="center"
        // alignItems="center"
        // direction={"column"}
      >
        <Stack gap={2}>
          <GoogleButton onClick={signInWithGoogle}></GoogleButton>
        </Stack>

        <Divider />
        <Stack>
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => navigate("/create")}
          >
            Create Account
          </Button>
        </Stack>
        <Divider />

        <Stack>
          {showCustomLogin ? (
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
              <Stack direction={"column"} gap={2} minHeight={theme.spacing(8)}>
                <Controller
                  control={control}
                  name={`email`}
                  defaultValue=""
                  render={({ field }) => {
                    return (
                      <TextField
                        {...field}
                        label="Email"
                        error={errors?.email}
                        helperText={errors?.email?.message}
                        // fullWidth
                      />
                    );
                  }}
                />
                <Controller
                  control={control}
                  name={`password`}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Password"
                      type="password"
                      error={errors?.email}
                      helperText={errors?.email?.message}
                      // fullWidth
                    />
                  )}
                />
                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                  sx={{ float: "right" }}
                >
                  Login
                </Button>
              </Stack>
            </Box>
          ) : (
            <Button
              variant="outlined"
              onClick={() => setShowCustomLogin(!showCustomLogin)}
            >
              Legacy Login
            </Button>
          )}
        </Stack>

        {/* <Button
            color="primary"
            type="submit"
            variant="contained"
            sx={{ float: "right" }}
          >
            Login
          </Button> */}
      </Stack>

      <Backdrop
        sx={{ color: "black", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isSubmitting}
        onClick={() => setSubmitting(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Centerer>
  );
};

export default Login;
