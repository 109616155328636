import { Button, AppBar, Toolbar, IconButton, Box } from "@mui/material";
import { Home } from "@mui/icons-material";
import { auth, signOut } from "../../firebase-connection";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const [authUser] = useAuthState(auth);

  const navigate = useNavigate();
  return (
    <AppBar color="primary" position="static">
      <Toolbar>
        <IconButton edge="start" color="inherit" aria-label="home">
          <Home color="secondary" />
        </IconButton>
        <Button
          onClick={() => {
            navigate("/");
          }}
          color="secondary"
        >
          Welcome {authUser?.displayName}
        </Button>
        <Box sx={{ flexGrow: 1 }} />{" "}
        <Button color="secondary" onClick={signOut}>
          Sign Out
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
