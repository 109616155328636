import React from "react";
import {
  BrowserRouter as RouterProvider,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { CreateAccount, Login } from "./auth";
import { Backdrop, CircularProgress } from "@mui/material";
import { auth } from "../firebase-connection";
import { useAuthState } from "react-firebase-hooks/auth";
import { Welcome, World, Lost } from "../pages";
import Frame from "./common/frame";

import {
  LocationPermissionProvider,
  useLocationPermission,
} from "../firebase-connection/LocationContext";

export default function AppRoutes() {
  return (
    <RouterProvider>
      <LocationPermissionProvider>
        <Routes>
          <Route
            path="/"
            element={
              <RequireAuth withLocationLoading>
                <Welcome />
              </RequireAuth>
            }
          />
          <Route
            path="/welcome"
            element={
              <RequireAuth withLocationLoading>
                <Welcome />
              </RequireAuth>
            }
          />

          <Route path="/create" element={<CreateAccount />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/world"
            element={
              <RequireAuth withLocationLoading>
                <World />
              </RequireAuth>
            }
          />
          <Route path="*" element={<Lost />} />
        </Routes>
      </LocationPermissionProvider>
    </RouterProvider>
  );
}

const routesRequiringLocationAccess = ["/world"];

function RequireAuth({ children, withLocationLoading = false, login = false }) {
  const [user, loading, error] = useAuthState(auth);
  const { locationLoading, locationData } = useLocationPermission();
  const funTurnedOff =
    (withLocationLoading &&
      locationData?.state === "denied" &&
      routesRequiringLocationAccess.includes(window.location.pathname)) ||
    error;

  if (loading || (withLocationLoading && (loading || locationLoading))) {
    return (
      <Backdrop
        open
        sx={{ color: "black", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  } else if (!user) {
    return <Navigate to="/login" />;
  } else if (funTurnedOff) {
    return <Navigate to="/welcome" />;
  }

  return <Frame includeCommonGridCenterer={true}>{children}</Frame>;
}
