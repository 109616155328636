import { Button, styled } from "@mui/material";

const GlossyButton = styled(Button)(({ theme, glossy }) => ({
  ...(glossy && {
    transition: "transform 0.3s ease",
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: "inherit",
      boxShadow: `1 1 30px ${theme.palette.primary.main}`,
      animation: "glossy-animation 3s infinite",
    },
  }),
}));

export default GlossyButton;
